<template>
  <v-sheet>
    <v-footer v-if="!isProfileLoading" :fixed="true">
      <v-row justify="center" align="center">
        <v-col class="d-flex justify-center">
          <v-spacer /><span class="font-weight-bold centered"><v-icon :color="registrationStageColorProp" right>mdi-circle-medium</v-icon>{{ registrationStageNameProp }}</span> <v-spacer />
        </v-col>

        <v-col class="d-flex justify-center">
          <v-card-actions>
            <v-btn small v-if="applicant.registrationstage === registrationStage.Submitted || applicant.registrationstage === registrationStage.Accepted" color="error" @click="showRejectDialog = true"><v-icon>mdi-cancel</v-icon>Reject</v-btn>
            <v-btn small v-if="applicant.registrationstage === registrationStage.Submitted || applicant.registrationstage === registrationStage.Accepted || applicant.registrationstage === registrationStage.Rejected" color="warning" @click="showReviseDialog = true"><v-icon>mdi-restart</v-icon>Ask for revision</v-btn>
            <v-btn small v-if="applicant.registrationstage === registrationStage.Submitted || applicant.registrationstage === registrationStage.Rejected" color="success" @click="showApproveDialog = true"><v-icon>mdi-check-decagram</v-icon>Approve</v-btn>
            <v-btn small v-if="this.registrationStageNameProp == 'Approved'" color="primary" @click="showLinkToStripeDialog = true"><v-icon left>mdi-link-variant</v-icon>Link to Stripe</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-footer>
    <v-row justify="center" align="center" class="footer">
      <v-col cols="12" sm="12">
        <v-snackbar loading v-model="showSavedProgress" :timeout="longTimeoutProp">
          Updating…
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="footer">
      <v-col cols="12" sm="12">
        <v-snackbar loading v-model="showSavedSuccess" :timeout="shortTimeoutProp" color="success"> Updated! </v-snackbar>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="footer">
      <v-col cols="12" sm="12">
        <v-snackbar v-model="showSavedFailure" :timeout="infinityTimeoutProp" color="error">
          <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
            <span class="centered">
              <span>Error while updating: {{ savedFailureErrorMessage }}</span>
              <v-spacer />
              <v-btn @click="showSavedFailure = false" text style="display: flex; justify-content: flex-end">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </span>
          </v-container>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="footer">
      <v-col cols="12" sm="12">
        <v-snackbar v-model="showSavedFailure" :timeout="infinityTimeoutProp" color="error">
          <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
            <span class="centered">
              <span>Error while updating: {{ savedFailureErrorMessage }}</span>
              <v-spacer />
              <v-btn @click="showSavedFailure = false" text style="display: flex; justify-content: flex-end">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </span>
          </v-container>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showLinkToStripeDialog" width="100%" transition="dialog-bottom-transition" closable>
        <v-card>
          <v-toolbar dark color="primary" dense elevation="0">Link to stripe</v-toolbar>
          <v-subheader>
            <v-icon left>mdi-info</v-icon>
            Use this tool to link the membership of&nbsp;
            <span :style="{ fontWeight: 'bold' }">{{ fullname }}</span>
            &nbsp;to their Stripe customer ID
          </v-subheader>

          <v-col cols="12" class="d-flex align-right"> </v-col>
          <v-col cols="12" class="d-flex align-center">
            <v-text-field v-model.trim="stripeCustomerID" color="primary" outlined clearable @input="showSearchFailure = false" @click:clear="reset()" label="Stripe Customer ID" placeholder="cus_••••••••••••••" required>
              <template v-slot:append>
                <v-icon color="primary">mdi-account-key</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" class="d-flex align-center">
            <v-text-field v-model.trim="oneTimePassword" color="primary" outlined clearable @input="showSearchFailure = false" @click:clear="reset()" label="Your one time authentication code" placeholder="Enter code…" required>
              <template v-slot:append>
                <v-icon color="primary">mdi-lock</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <br />
          <v-card-actions>
            <v-spacer />
            <v-btn color="white" class="font-weight-bold" min-width="96" @click="showLinkToStripeDialog = false">Cancel</v-btn>
            <v-btn color="primary" class="font-weight-bold" min-width="96" @click="linkStripeCustomerIDAction">Link to Stripe</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showApproveDialog" width="100%" transition="dialog-bottom-transition" closable>
        <v-card>
          <v-toolbar dark class="success" dense elevation="0"><v-icon left>mdi-check-decagram</v-icon>Approve the application</v-toolbar>
          <br />
          <v-list-item>You are about to approve {{ fullname }}‘s application…</v-list-item><br />
          <v-card-actions>
            <v-spacer />
            <v-btn color="white" class="font-weight-bold" min-width="96" @click="showApproveDialog = false">Later</v-btn>
            <v-btn color="success" class="font-weight-bold" min-width="96" @click="applicationApprovedAction()">Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showRejectDialog" width="100%" transition="dialog-bottom-transition" closable>
        <v-card>
          <v-toolbar dark class="error" dense elevation="0"><v-icon left>mdi-cancel</v-icon>Reject the application permanently</v-toolbar>
          <br />
          <v-list-item>You are about to reject this application…</v-list-item>
          <v-list-item>If you just needed more information, send it back to {{ fullname }} instead.</v-list-item><br />
          <v-card-actions>
            <v-spacer />
            <v-btn color="white" class="font-weight-bold" min-width="96" @click="showRejectDialog = false">Nevermind</v-btn>
            <v-btn color="error" class="font-weight-bold" min-width="96" @click="applicationRejectedAction()">Reject</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showReviseDialog" width="100%" transition="dialog-bottom-transition" closable>
        <v-card>
          <v-toolbar dark color="warning" dense elevation="0"><v-icon left>mdi-restart</v-icon>Ask the application to be revised</v-toolbar>
          <br />
          <v-list-item>You are about to send this application back for revision to {{ fullname }}…</v-list-item><br />
          <v-card-actions>
            <v-spacer />
            <v-btn color="white" class="font-weight-bold" min-width="96" @click="showReviseDialog = false">Not yet</v-btn>
            <v-btn color="warning" class="font-weight-bold" min-width="96" @click="applicationIncompleteAction()">Ask for revision</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-sheet>
</template>

<script>
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX } from '@/environments/EnvX.ts'
import { RegistrationStage, AlertTimeout } from '@/RegistrationStage.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  data() {
    return {
      applicant: [],
      formProfile: {},
      fullname: '',
      genericFailureErrorMessage: '',
      isProfileLoading: false,
      networkname: '',
      oneTimePassword: '',
      savedFailureErrorMessage: '',
      showApproveDialog: false,
      showGenericFailure: false,
      showLinkToStripeDialog: false,
      showRejectDialog: false,
      showReviseDialog: false,
      showSavedFailure: false,
      showSavedProgress: false,
      showSavedSuccess: false,
      stripeCustomerID: ''
    }
  },

  mounted() {
    const paramsRoutes = this.$route.params
    EnvX.assert(paramsRoutes.person != null, '⧄ person')
    this.applicant = paramsRoutes.person
    setTimeout(this.fetchProfile, this.nullTimeoutProp)
  },

  computed: {
    nullTimeoutProp() {
      return AlertTimeout.null
    },

    longTimeoutProp() {
      return AlertTimeout.long
    },

    infinityTimeoutProp() {
      return AlertTimeout.infinity
    },

    shortTimeoutProp() {
      return AlertTimeout.short
    },

    registrationStage() {
      return RegistrationStage
    },
    registrationStageNameProp() {
      switch (this.applicant.registrationstage) {
        case RegistrationStage.Submitted:
          return 'Pending Review'
        case RegistrationStage.Rejected:
          return 'Rejected'
        case RegistrationStage.Accepted:
          return 'Approved'
        default:
          return 'Invalid'
      }
    },
    registrationStageColorProp() {
      switch (this.applicant.registrationstage) {
        case RegistrationStage.Submitted:
          return 'primary'
        case RegistrationStage.Rejected:
          return 'error'
        case RegistrationStage.Accepted:
          return 'success'
        default:
          return 'black'
      }
    }
  },

  methods: {
    applicationApprovedAction() {
      this.showApproveDialog = false
      this.applicationReviewAction(RegistrationStage.Accepted)
    },

    applicationIncompleteAction() {
      this.showReviseDialog = false
      this.applicationReviewAction(RegistrationStage.NotSubmitted)
    },

    applicationRejectedAction() {
      this.showRejectDialog = false
      this.applicationReviewAction(RegistrationStage.Rejected)
    },

    applicationReviewAction(stage) {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🥁 ProfileApprovalFooter.applicationReviewAction', // log hint
        endpoints.manageRegistrationEndpoint, // endpoint
        environmentVariables.MANAGE_REGISTRATION_KEY, // code
        { id: this.applicant.id, stage: stage }, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.showSavedProgress = true
          this.showSavedFailure = false
          this.showSavedSuccess = false

          return true
        },

        // onSuccess
        (_data) => {
          this.showSavedProgress = false
          this.showSavedSuccess = true

          this.$router.push({ name: 'approvalsroute' })
        },

        // All sort of errors
        (error, errorDescription) => {
          this.showSavedProgress = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showSavedFailure = true
            this.savedFailureErrorMessage = errorDescription
          }
        }
      )
    },

    fetchProfile() {
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🤔 ProfileApprovalFooter.fetchProfile', // log hint
        endpoints.userLookupEndpoint, // endpoint
        environmentVariables.USER_LOOKUP_KEY, // code
        { id: this.applicant.id }, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.isProfileLoading = true
          this.showGenericFailure = false
          this.genericFailureErrorMessage = ''

          return true
        },

        // onSuccess
        (data) => {
          this.isProfileLoading = false
          const profile = data.profile
          this.formProfile = profile
          this.fullname = profile.fullname
          this.networkname = profile.networkname
        },

        // All sort of errors
        (error, errorDescription) => {
          this.isProfileLoading = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showGenericFailure = true
            this.genericFailureErrorMessage = errorDescription
          }
        }
      )
    },

    linkStripeCustomerIDAction() {
      const stripeAdminBody = {
        id: this.applicant.id,
        otp: this.oneTimePassword,
        stripecustomerid: this.stripeCustomerID,
        action: 1
      }

      this.showLinkToStripeDialog = false
      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '🥁 ProfileApprovalFooter.applicationReviewAction', // log hint
        endpoints.stripeAdministatorEndPoint, // endpoint
        environmentVariables.STRIPE_ADMINISTRATOR_KEY, // code
        stripeAdminBody, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.showSavedProgress = true
          this.showSavedFailure = false
          this.showSavedSuccess = false

          return true
        },

        // onSuccess
        (_data) => {
          this.showSavedProgress = false
          this.showSavedSuccess = true

          // this.$router.push({ name: 'approvalsroute' })
        },

        // All sort of errors
        (error, errorDescription) => {
          this.showSavedProgress = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            this.showSavedFailure = true
            this.savedFailureErrorMessage = errorDescription
          }
        }
      )
    },

    showLoggedOutBanner() {
      // MustLoginSection should already have a modal
    }
  }
}
</script>

<style scoped>
.footer {
  margin: 0;
}

.centered {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
</style>
